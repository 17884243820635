<template>
  <div ref="target">
    <slot v-if="targetIsVisible" />
  </div>
</template>

<script lang="ts" setup>
const target = ref(null)
const targetIsVisible = ref(false)

const emit = defineEmits(['load'])

useIntersectionObserver(
  target,
  ([{ isIntersecting }]) => {
    if (isIntersecting && !targetIsVisible.value) {
      targetIsVisible.value = true
      emit('load')
    }
  },
  {
    immediate: true,
  }
)
</script>
